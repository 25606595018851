import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { CheckboxWrapper, StyledCheckbox, CheckboxLabel } from './styles'

const CheckBoxToggler = ({ onChange, label, checked }) => {
  const [isChecked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(checked)
  }, [])

  const handleCheckbox = (e) => {
    setChecked(!isChecked)
    onChange(e)
  }
  return (
    <CheckboxWrapper>
      <CheckboxLabel>
        <StyledCheckbox onChange={handleCheckbox} checked={isChecked} />
        {label}
      </CheckboxLabel>
    </CheckboxWrapper>
  )
}

export default CheckBoxToggler

CheckBoxToggler.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string
}
