import React from 'react'

const DoubleArrow = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 511.626 511.627">
      <path d="M506.203 242.966l-73.087-73.089c-3.621-3.617-7.902-5.424-12.847-5.424-4.949 0-9.233 1.807-12.854 5.424-3.613 3.616-5.42 7.898-5.42 12.847v36.547H109.636v-36.547c0-4.949-1.809-9.231-5.426-12.847-3.619-3.617-7.902-5.424-12.85-5.424-4.947 0-9.23 1.807-12.847 5.424L5.424 242.966C1.809 246.58 0 250.865 0 255.813c0 4.947 1.809 9.232 5.424 12.845l73.089 73.091c3.617 3.613 7.897 5.424 12.847 5.424 4.952 0 9.234-1.811 12.85-5.424 3.617-3.614 5.426-7.898 5.426-12.847v-36.549h292.359v36.549c0 4.948 1.807 9.232 5.42 12.847 3.621 3.613 7.905 5.424 12.854 5.424 4.944 0 9.226-1.811 12.847-5.424l73.087-73.091c3.617-3.613 5.424-7.898 5.424-12.845-.001-4.948-1.807-9.233-5.424-12.847z" />
    </svg>
  )
}

DoubleArrow.defaultProps = {
  width: 30,
  height: 25
}

export default DoubleArrow
