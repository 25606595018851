import styled, { css } from 'styled-components'

const gradientText = css`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.veryDarkBlue},
    ${({ theme }) => theme.colors.darkBlue}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const PropertyPickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 940px;
  background: linear-gradient(180deg, rgba(242, 242, 242, 0) 0%, #f2f2f2 93.41%);
  overflow: hidden;
  position: relative;

  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  }

  @media screen and (max-width: 967px) {
    height: 100%;
    padding: 14rem 0 15rem 0;
    clip-path: polygon(0 0, 100% 0, 100% 96%, 0 100%);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: 0;
      height: 20%;
      transform: translateY(-2%);
      background: radial-gradient(
          80.8% 43.45% at 61.24% 43.45%,
          rgba(169, 207, 58, 0.26) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        radial-gradient(74.86% 40.26% at 59.9% 92.65%, rgba(29, 158, 217, 0.47) 0%, rgba(29, 158, 217, 0) 100%);
      z-index: -5;
      opacity: 0.4;
      pointer-events: none;
    }
  }
`
export const Title = styled.h1`
  font-family: 'Barlow';
  font-size: 4.28rem;
  font-weight: 800;
  line-height: 5.14rem;
  text-align: center;
  margin-bottom: 4rem;
  ${gradientText};

  @media screen and (max-width: 1180px) {
    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    width: 80%;
    margin: 0 auto 4rem auto;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    font-size: 3rem;
    line-height: 3.4rem;
    margin-bottom: 3rem;
  }
`
export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: inherit;
  margin: 0 auto 7rem auto;
  text-align: center;
  width: 40%;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0 auto 2rem auto;
  }
`

export const PropertyPickerBoxes = styled.div`
  display: flex;
  justify-content: center;

  & > label {
    margin: 0 3rem;
  }

  @media screen and (min-width: 968px) and (max-width: 1180px) {
    & > label {
      margin: 0 1rem;
    }
  }

  @media screen and (max-width: 967px) {
    flex-direction: column;
    align-items: center;

    & > label {
      margin: 3rem auto;
    }
  }
`
export const PropertyPickerBoxLabel = styled.label`
  cursor: pointer;

  input {
    display: none;

    &:checked + .picker-box {
      transform: translateY(-20px);
      border-color: ${({ theme }) => theme.colors.vividBlue};
    }

    &:checked + .picker-box svg {
      opacity: 1;
    }

    &:checked + .picker-box::after {
      opacity: 0.4;
    }

    @media screen and (max-width: 967px) {
      &:checked + .picker-box {
        transform: translateY(0px);
      }
    }
  }
`
export const PropertyPickerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 220px;
  height: 252px;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 2px solid;
  border-color: transparent;
  transition: transform 0.3s ease-in-out, border-color 0.15s ease-in-out;

  &:hover {
    transform: translateY(-20px);
    &::after {
      opacity: 0.4;
    }

    figure {
      transform: rotate3d(10px);
    }
  }

  svg {
    opacity: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    transition: opacity 0.15s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 1000px;
    background-size: 500px;
    height: 100%;
    transform: translateY(100%);
    transition: all 0.3s ease-in-out;
    background: radial-gradient(
        49.52% 46.34% at 50.48% 46.34%,
        rgba(169, 207, 58, 0.26) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(45.88% 42.93% at 49.66% 98.81%, rgba(29, 158, 217, 0.47) 0%, rgba(29, 158, 217, 0) 100%);
    opacity: 0;
    z-index: -5;
    pointer-events: none;
  }

  figure {
    width: 150px;

    img {
      pointer-events: none;
    }
  }

  span {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: 1.42rem;
    font-weight: 500;
    line-height: 2.14rem;
  }

  @media screen and (min-width: 968px) and (max-width: 1180px) {
    width: 210px;
  }

  @media screen and (max-width: 967px) {
    &::after {
      display: none;
    }

    &:hover {
      transform: none;
    }
  }
`
