import React, { useState } from 'react'

import {
  PropertyPickerWrapper,
  PropertyPickerBoxes,
  PropertyPickerBoxLabel,
  PropertyPickerBox,
  Title,
  Subtitle
} from './styles'

import { LocaleConsumer } from '../../Layout'
import Container from '../../UI/Container'
import CheckedBlueIcon from './checked'

import { usePropertyImages } from '../../../graphql/usePropertyImagesHook'
import { scrollTo } from '../../../helper'

const PropertyPicker = ({ handlePickChange, handleImgURLChange, title, subtitle }) => {
  const { HotelImgTwo, HostelImgTwo, VacationImgTwo, PropertyManagersImgTwo } = usePropertyImages()
  const [propertyChecked, setPropertyChecked] = useState(false)

  const checkIsHostel = (e) => {
    let isHostel = false
    if (parseInt(e.target.dataset.id) === 2) {
      isHostel = true
      handlePickChange(isHostel)
    }
    handleImgURLChange(parseInt(e.target.dataset.id))
    handlePickChange(isHostel)
  }

  const onPropertyChecked = (e) => {
    checkIsHostel(e)
    setPropertyChecked(!propertyChecked)
    setTimeout(() => {
      scrollTo(document.documentElement, document.querySelector('#pricing-section').offsetTop, 500)
    }, 500)
  }

  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <PropertyPickerWrapper>
          <Container>
            <div>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </div>
            <PropertyPickerBoxes>
              <PropertyPickerBoxLabel>
                <input type="radio" name="property" onChange={onPropertyChecked} data-id="1" />
                <PropertyPickerBox className="picker-box">
                  <CheckedBlueIcon />
                  <figure>
                    <img src={HotelImgTwo.childImageSharp.fixed.src} alt="" />
                  </figure>
                  <span>Hotel</span>
                </PropertyPickerBox>
              </PropertyPickerBoxLabel>

              <PropertyPickerBoxLabel>
                <input type="radio" name="property" onChange={onPropertyChecked} data-id="2" />
                <PropertyPickerBox className="picker-box">
                  <CheckedBlueIcon />
                  <figure>
                    <img src={HostelImgTwo.childImageSharp.fixed.src} alt="" />
                  </figure>
                  <span>Hostel</span>
                </PropertyPickerBox>
              </PropertyPickerBoxLabel>

              <PropertyPickerBoxLabel>
                <input
                  type="radio"
                  name="property"
                  onChange={onPropertyChecked}
                  defaultChecked={!propertyChecked}
                  data-id="3"
                />
                <PropertyPickerBox className="picker-box">
                  <CheckedBlueIcon />
                  <figure>
                    <img src={VacationImgTwo.childImageSharp.fixed.src} alt="" />
                  </figure>
                  <span>{i18n.vacationRentals}</span>
                </PropertyPickerBox>
              </PropertyPickerBoxLabel>
              <PropertyPickerBoxLabel>
                <input type="radio" name="property" onChange={onPropertyChecked} data-id="4" />
                <PropertyPickerBox className="picker-box">
                  <CheckedBlueIcon />
                  <figure>
                    <img src={PropertyManagersImgTwo.childImageSharp.fixed.src} alt="" />
                  </figure>
                  <span>{i18n.propertyManagers}</span>
                </PropertyPickerBox>
              </PropertyPickerBoxLabel>
            </PropertyPickerBoxes>
          </Container>
        </PropertyPickerWrapper>
      )}
    </LocaleConsumer>
  )
}

export default PropertyPicker
