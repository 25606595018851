import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Header from 'components/Header'
import Footer from 'components/Footer'
import PropertyPicker from 'components/Pricing-screen/Property-picker'
import AdvancedPricingCalculator from 'components/Calculator/Advanced'
import FAQ from 'components/FAQ'

const MainContent = styled.main`
  background-color: #ffffff;
`

const Pricing = ({ data, pageContext, location }) => {
  const {
    pricing: {
      title,
      excerpt,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      acf: {
        title: pickerTitle,
        subtitle,
        faq: { faq_title, questions },
        calculator
      }
    }
  } = data

  const [isHostel, setIsHostel] = useState(false)
  const [imgData, setImgData] = useState(calculator.vacation_rentals_info)

  const imagesData = {
    1: calculator.hotel_info,
    2: calculator.hostel_info,
    3: calculator.vacation_rentals_info,
    4: calculator.property_managers_info
  }

  const handleImgURLChange = (id) => {
    setImgData(imagesData[id])
  }

  const handlePickChange = (isHostel) => {
    setIsHostel(isHostel)
  }

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc || excerpt,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
      />
      <Header mode="dark" locale={pageContext.locale} opacity={0.3} location={location} />
      <PropertyPicker
        handlePickChange={handlePickChange}
        handleImgURLChange={handleImgURLChange}
        title={pickerTitle}
        subtitle={subtitle}
      />
      <MainContent>
        <AdvancedPricingCalculator
          isHostel={isHostel}
          data={calculator}
          locale={pageContext.locale}
          imgData={imgData}
          location={location}
        />
        <FAQ questions={questions} title={faq_title} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
    </Layout>
  )
}

export default Pricing

export const query = graphql`
  query($id: Int, $locale: String!) {
    pricing: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      excerpt
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        title
        subtitle
        faq {
          faq_title
          questions {
            title
            content
          }
        }
        calculator {
          title
          button_text
          button_link
          hotel_info {
            customer_name
            property_name
            message
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 95, maxWidth: 590) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          hostel_info {
            customer_name
            property_name
            message
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 95, maxWidth: 590) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          vacation_rentals_info {
            customer_name
            property_name
            message
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 95, maxWidth: 590) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          property_managers_info {
            customer_name
            property_name
            message
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 95, maxWidth: 590) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
