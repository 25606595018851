import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { handleTrackedRentlioWebappClick } from 'helper/url'

import {
  PricingSection,
  Title,
  PricingWrapper,
  PricingSliderWrapper,
  PricingSlider,
  CalculationWrapper,
  FinalPrice,
  ReplyMsg,
  MontlyPrice,
  SavingsText,
  Name,
  FadeIn,
  ColumnLeft,
  ColumnRight,
  ImageContainer,
  ImageContainerText,
  FinalPriceWrapper,
  MontlyPricePerUnit,
  List,
  Convert,
  ConvertFinal,
  SpanPrice
} from './styles'

import { LocaleConsumer } from '../../Layout'

import Container from '../../UI/Container'
import Button from '../../Button'

import { priceCalculation, formatCurrency, validateNumericInput } from '../../../helper'
import CheckBoxToggler from '../Toggle'
import DoubleArrow from '../../UI/Icons/DoubleArrow'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const PricingAdvanced = ({ isHostel, data, locale, imgData, location }) => {
  const { title, button_text, button_link } = data

  const {
    customer_name,
    property_name,
    message,
    image: {
      localFile: {
        childImageSharp: { fluid }
      }
    }
  } = imgData

  const [channelManager, setChannelManager] = useState(true)
  const [sliderValue, setSliderValue] = useState(10)
  const [finalPrice, setFinalPrice] = useState('')
  const [isChanged, setIsChanged] = useState(false)

  const currency = 'EUR'

  const toogleCheckBox = (e) => {
    setChannelManager(e.target.checked)
  }

  const handleValidation = (e) => {
    validateNumericInput(e)
  }

  const mixpanel = useMixpanel()

  const handleSliderChange = (e) => {
    setIsChanged(true)
    setSliderValue(Number(e.target.value))
    setFinalPrice(priceCalculation(e.target.value, channelManager, isHostel, 'EUR'))
  }

  useEffect(() => {
    setFinalPrice(priceCalculation(sliderValue, channelManager, isHostel, currency))
  }, [channelManager, isHostel])

  const overFiftyUnits = (sliderValue, i18n) => {
    if (sliderValue > 15) {
      return (
        <FadeIn duration="0.8s" delay="0.2s">
          <FinalPrice overFifty={sliderValue} dangerouslySetInnerHTML={{ __html: i18n.finalPriceMsg }} />
          <ReplyMsg overFifty={sliderValue} dangerouslySetInnerHTML={{ __html: i18n.replyMsg }} />
        </FadeIn>
      )
    }
    return (
      <FinalPrice currency={currency}>
        {i18n.for} <input type="text" value={sliderValue} onChange={handleSliderChange} onKeyPress={handleValidation} />
        {i18n.priceMsg}
        <span>{formatCurrency(finalPrice, 'en')}</span>{' '}
        {/* <SpanPrice>
          {locale === 'hr' ? <ConvertFinal>({formatCurrency(finalPrice * 7.5, 'hr')})</ConvertFinal> : <div></div>}
        </SpanPrice> */}
      </FinalPrice>
    )
  }

  const pricePerMonth = () => {
    return formatCurrency(finalPrice / sliderValue, 'en')
  }

  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <PricingSection id="pricing-section">
          <Container>
            <Title>{title}</Title>
            <SavingsText>{i18n.accommodation}</SavingsText>
            <PricingWrapper>
              <CalculationWrapper>{overFiftyUnits(sliderValue, i18n)}</CalculationWrapper>
              <PricingSliderWrapper>
                {!isChanged && <DoubleArrow />}
                <PricingSlider min="1" max="16" step="1" value={sliderValue || 1} onChange={handleSliderChange} />
              </PricingSliderWrapper>
            </PricingWrapper>

            <FinalPriceWrapper>
              <ColumnLeft>
                {sliderValue > 15 ? (
                  overFiftyUnits(sliderValue, i18n)
                ) : (
                  <>
                    <CheckBoxToggler onChange={toogleCheckBox} label={i18n.checkboxCM} checked={channelManager} />
                    <MontlyPricePerUnit>
                      {pricePerMonth()}
                      {i18n.perUnit}
                    </MontlyPricePerUnit>
                    <MontlyPrice>
                      {formatCurrency(finalPrice, 'en')}
                      <span>
                        {locale === 'hr' ? (
                          <Convert>({formatCurrency(finalPrice * 7.5345, 'hr')})</Convert>
                        ) : (
                          <div></div>
                        )}
                      </span>
                      <span>{i18n.perMonth}</span>
                    </MontlyPrice>
                    <Button
                      type="large"
                      href={`${button_link}${location.search}`}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) =>
                        handleTrackedRentlioWebappClick(
                          e,
                          button_link,
                          location.search,
                          mixpanel,
                          'try_free_button_clicked',
                          'page_middle',
                          true,
                          true,
                          true
                        )
                      }
                    >
                      {button_text}
                    </Button>
                    <span>{i18n.vatExcluded}</span>
                    <span>{i18n.bookinEngineCost}</span>
                  </>
                )}
              </ColumnLeft>
              <ColumnRight>
                <ImageContainer imageUrl={fluid.src}>
                  <Name>
                    {customer_name} <span>{property_name}</span>
                  </Name>
                  <ImageContainerText>{message}</ImageContainerText>
                </ImageContainer>
              </ColumnRight>
            </FinalPriceWrapper>
            <List>
              <li>{i18n.pricingBenefits.easy}</li>
              <li>{i18n.pricingBenefits.card}</li>
              <li>{i18n.pricingBenefits.cancel}</li>
              <li>{i18n.pricingBenefits.fees}</li>
            </List>
          </Container>
        </PricingSection>
      )}
    </LocaleConsumer>
  )
}

PricingAdvanced.propTypes = {
  location: PropTypes.object
}

PricingAdvanced.defaultProps = {
  location: {
    search: ''
  }
}

export default PricingAdvanced
