import React from 'react'

const CheckedBlueIcon = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 6.73684L6 12L16 2" stroke="#1D9ED9" strokeWidth="3" />
    </svg>
  )
}

export default CheckedBlueIcon
