import styled, { css, keyframes } from 'styled-components'

import checkedIcon from '../../../images/checked-green-icon-2.svg'

export const PricingSection = styled.section`
  padding: 5rem 0;

  @media screen and (max-width: 425px) {
    padding: 5rem 0 5rem 0;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 3.28rem;
  font-weight: 800;
  line-height: 3.57rem;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 7rem;

  @media screen and (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-top: 2rem;
  }
`

export const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const PricingSliderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem 0;
  position: relative;

  svg {
    fill: ${({ theme }) => theme.colors.vividBlue};
    position: absolute;
    left: 57.5%;
    transform: translate(0px, -40px);
    animation: moveLeftRight 2s ease-in-out infinite both;

    @keyframes moveLeftRight {
      from {
        transform: translate(0, -40px);
        transform-origin: center center;
        animation-timing-function: ease-out;
      }
      50% {
        transform: translate(15px, -40px);
        transform-origin: center center;
        animation-timing-function: ease-out;
      }
      100% {
        transform: translate(0px, -40px);
        animation-timing-function: ease-in;
      }
    }

    @media (max-width: 750px) {
      left: 56%;
    }
    @media (max-width: 500px) {
      left: 55%;
    }
    @media (max-width: 450px) {
      left: 54%;
    }
    @media (max-width: 390px) {
      left: 52%;
    }
  }
`

const fillValue = (min, max, sliderValue) => {
  if (sliderValue >= 16) {
    sliderValue = 16
  }

  if (sliderValue === 0) {
    sliderValue = 10
  }

  return Math.round(100 * ((sliderValue - min) / (max - min))) + (sliderValue > 13 ? -0.2 : 0.2)
}

const rangeTrack = css`
  border-color: transparent;
  border-radius: 8px;
  height: 10px;
  ${({ value }) => ` background: linear-gradient(
        90deg,
        #a9cf3a 0%,
        #a7cf40 5.15%,
        #a2ce52 12.91%,
        #99cd6f 22.31%,
        #8bcb92 32.97%,
        #78cabb 44.65%,
        #59c9e7 56.32%,
        #1d9ed9 99.98%
      )
      0 / ${fillValue(1, 16, value)}% 100% no-repeat transparent;
    }`}
`
const rangeThumb = css`
  appearance: none;
  width: 27px;
  height: 27px;
  background-color: ${({ theme }) => theme.colors.vividBlue};
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 50%;
  margin-top: -9px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: none;
  left: 10px;

  &:hover {
    transform: scale(1.1);
  }
`
export const PricingSlider = styled.input.attrs({
  type: 'range'
})`
  position: relative;
  appearance: none;
  width: 100%;
  height: 10px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.lightestGrey};
  border-radius: 8px;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    ${rangeThumb}
  }

  &::-webkit-slider-thumb::after {
    ${rangeThumb}
  }
  &::-moz-focus-outer {
    border: 0;
  }
  &::-moz-range-thumb {
    ${rangeThumb}
  }
  &::-moz-range-track {
    ${rangeTrack}
  }
  &::-webkit-slider-runnable-track {
    ${rangeTrack}
  }
`
export const CalculationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0 6rem 0;
  text-align: center;

  @media screen and (max-width: 768px) {
    order: 1;
    margin-bottom: 1rem;
    margin-top: 0;
  }
`

const BaseAnimation = styled.div`
  animation-duration: ${({ duration }) => duration};
  animation-timing-function: ${({ timingFunction }) => timingFunction};
  animation-delay: ${({ delay }) => delay};
  animation-iteration-count: ${({ iterationCount }) => iterationCount};
  animation-direction: ${({ direction }) => direction};
  animation-fill-mode: ${({ fillMode }) => fillMode};
  animation-play-state: ${({ playState }) => playState};
  display: ${({ display }) => display};
`
BaseAnimation.defaultProps = {
  duration: '1s',
  timingFunction: 'ease',
  delay: '0s',
  iterationCount: '1',
  direction: 'normal',
  fillMode: 'both',
  playState: 'running',
  display: 'block'
}

const FadeInAnimation = keyframes`  
  from { opacity: 0; }
  to { opacity: 1; }
`

const FadeOutAnimation = keyframes`  
  from { opacity: 1; }
  to { opacity: 0; }
`
export const FadeIn = styled(BaseAnimation)`
  animation-name: ${FadeInAnimation};
`

export const FadeOut = styled(BaseAnimation)`
  animation-name: ${FadeOutAnimation};
`
export const ReplyMsg = styled.div`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.71rem;
  font-weight: 600;
  line-height: ${({ overFifty }) => (overFifty >= 50 ? '2rem' : '2.14rem')};
  margin-top: 1rem;
  padding: 0 3rem;

  @media screen and (max-width: 425px) {
    font-size: 1rem;
    line-height: 1.2rem;
  }
`

export const FinalPrice = styled.div`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.71rem;
  font-weight: 600;
  line-height: ${({ overFifty }) => (overFifty >= 50 ? '2rem' : '2.14rem')};
  margin-bottom: 1.06rem;
  padding: 0 3rem;

  input {
    color: inherit;
    font-size: 3.28rem;
    font-weight: 800;
    line-height: 2.14rem;
    width: 100px;
    border: none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.vividBlue};
    text-align: center;
    margin: 0 1.5rem;
    letter-spacing: 3px;

    &:focus {
      outline: none;
    }
  }

  span {
    display: inline-flex;
    justify-content: center;
    width: ${({ currency }) => (currency === 'HRK' ? '300px' : '200px')};
    margin: 0 auto;
    font-family: inherit;
    font-size: 3.28rem;
    font-weight: 800;
    line-height: 3.57rem;
  }

  a {
    color: ${({ theme }) => theme.colors.vividBlue};
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    input {
      margin: 0.5rem 0 2rem 0;
    }
    span {
      margin: 1rem 0 2rem 0;
    }
  }

  @media screen and (max-width: 425px) {
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0 1rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
    span {
      margin: 1rem 0 -1rem 0;
    }
  }
`
export const MontlyPrice = styled.p`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 5.14rem;
  font-weight: 900;
  line-height: initial;
  margin-bottom: 3rem;

  & > span {
    display: block;
    width: 100%;
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1.42rem;
    font-weight: 500;
    line-height: 2.14rem;
    text-align: center;
  }

  @media only screen and (max-width: 425px) {
    font-size: 3.14rem;
  }
`

export const MontlyPricePerUnit = styled.p`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.71rem;
  font-weight: 600;
  line-height: 2.14rem;
  margin: 2rem 0;
`
export const Convert = styled.p`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2rem;
  font-weight: 300;
  line-height: 1rem;
  margin: 2rem 0;
`
export const ConvertFinal = styled(Convert)`
  margin: 0;
`

export const SavingsText = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  line-height: 1.71rem;
  margin: 5rem 0;
  text-align: center;

  @media screen and (max-width: 768px) {
    order: 1;
    margin: 3rem 0 1rem 0;
  }
`

export const FinalPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 7rem 0 10rem 0;
  height: 561px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin-top: 1rem;
    height: auto;
  }
`
export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > span {
    color: ${({ theme }) => theme.colors.darkGrey};

    :not(:last-child) {
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 3rem;
    & > span {
      margin-bottom: 4rem;
    }
  }

  @media only screen and (max-width: 425px) {
    margin-bottom: 1rem;
  }
`

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  align-items: center;
`
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 590px;
  height: 560px;
  background: linear-gradient(180deg, rgba(13, 49, 81, 0) 33.25%, #0d3151 100%),
    url(${({ imageUrl }) => imageUrl}) no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: -20px 0px 30px rgba(47, 47, 47, 0.05), 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 2rem;
  }
`

export const Name = styled.div`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-weight: 900;
  font-size: 1.28rem;
  line-height: 1.5rem;

  span {
    color: ${({ theme }) => theme.colors.veryLightGray};
    font-size: 1.14rem;
    font-weight: normal;
    line-height: 1.71rem;
    margin-left: 0.7rem;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

export const ImageContainerText = styled.p`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-family: 'Barlow';
  font-size: 2.14rem;
  font-weight: 800;
  line-height: 2.85rem;
  padding: 1rem 3rem 3rem 3rem;
  text-align: center;

  @media screen and (max-width: 425px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`
export const List = styled.ul`
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 1.28rem;
  margin-top: 7rem;

  background: radial-gradient(398.94% 277.46% at 9.13% 13.77%, #0d3151 0%, #134776 100%);
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 10px;

  li {
    display: flex;
    align-items: center;
    padding: 3.57rem 15px;

    &::before {
      content: '';
      display: inline-flex;
      width: 22px;
      height: 22px;
      background-image: url(${checkedIcon});
      background-repeat: no-repeat;
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    width: 70%;
    margin: 0 auto;

    li {
      padding: 1.5rem 0;
    }
  }
  @media screen and (max-width: 560px) {
    li {
      padding: 1rem 2rem;
      align-self: flex-start;
    }
  }

  @media screen and (max-width: 425px) {
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    font-size: 1rem;
    padding: 1.5rem 0;

    li {
      padding: 1rem 2rem;
      align-self: flex-start;
    }
  }
`

export const SpanPrice = styled.div`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  display: inline-flex;
  justify-content: center;
  /* width: ${({ currency }) => (currency === 'HRK' ? '300px' : '200px')}; */
  width: 130px;
  margin: 0 auto;
  font-family: inherit;
  font-size: 3.28rem;
  font-weight: 800;
  line-height: 3.57rem;

  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    margin: 0;
  }

  @media screen and (max-width: 425px) {
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 0 1rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
`
