import styled from 'styled-components'

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 6.2rem;

  @media screen and (max-width: 1024px) {
    margin-bottom: 2rem;
  }
`
export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    font-size: 1.14rem;
    text-align: center;
  }
`

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: relative;
  width: 54px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.lightestGrey};
  border: 1px solid ${({ theme }) => theme.colors.veryLightGray};
  margin-right: 2rem;
  appearance: none;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out, border-color 0.2s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.colors.vividBlue};
  }

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    top: 6px;
    transform: translateX(4px);
    background-color: ${({ theme }) => theme.colors.darkGrey};
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  &:checked {
    background-color: ${({ theme }) => theme.colors.vividBlue};
    border-color: ${({ theme }) => theme.colors.vividBlue};
  }

  &:checked::before {
    transform: translateX(30px);
    background-color: ${({ theme }) => theme.colors.pureWhite};
  }

  @media screen and (max-width: 1024px) {
    margin-right: 0;
    width: 108px;
    height: 60px;
    margin-bottom: 2.85rem;

    &::before {
      width: 32px;
      height: 32px;
      top: 13px;
      transform: translateX(11px);
    }

    &:checked::before {
      transform: translateX(63px);
    }
  }

  @media screen and (max-width: 425px) {
    margin-right: 0;
    width: 88px;
    height: 40px;
    margin-bottom: 2.85rem;

    &::before {
      width: 25px;
      height: 25px;
      top: 7px;
      transform: translateX(8px);
    }

    &:checked::before {
      transform: translateX(53px);
    }
  }
`
